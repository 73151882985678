<template>
  <div class="abb-box">
    <div class="uncultivated">
      <!-- <img src="" alt="lay" /> -->
      <van-image
        width="100%"
        v-for="(item,index) in imgList"
        :key="index"
        :src="item.src"
      />
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      imgList:[
        {src:this.GLOBAL.imgURL + 'introduce1.png'},
        {src:this.GLOBAL.imgURL + 'introduce2.png'},
        {src:this.GLOBAL.imgURL + 'introduce3.png'},
        {src:this.GLOBAL.imgURL + 'introduce4.jpg'}
      ],
      
    }
  }
};
</script>

<style scoped>
.abb-box{
  margin-bottom: 50px;
}
.uncultivated {
  text-align: center;
  font-size: 0.4rem;
  line-height: 2rem;
}
.uncultivated img {
  width: 100%;
  margin-bottom: 1rem;
}
::v-deep.van-image{
  display: block;
}
</style>